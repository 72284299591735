@import '../../assets/variables.scss';

.screen-container {
    padding: 1rem $body_padding;
    height: 100%;
    overflow: auto;
    background-image: url('../../assets/sun.svg');
    // background-size: 50rem;
    // background-position: center;
    background-repeat: no-repeat;
    filter: drop-shadow(-2px 1px 6px rgba(0, 0, 50, 0.5));

}

.inner-panel {
    height: 100%;
    //background-color: rgba(240, 255, 255, 0.90);
    background: 
        linear-gradient(
            45deg, 
            rgba(245, 250, 255, .6), 
            rgba(230, 245, 255, .6), 
            rgba(245, 250, 255, .6)
        ),
        linear-gradient(
            135deg, 
            rgba(255, 255, 0, .15) 5%, 
            rgba(255, 255, 255, 0)
        );
    overflow: auto;
    padding: 1rem 2rem;
    border-radius: .75rem;
    box-shadow: 
        inset 0 0 10px 4px rgba(240, 240, 255, .8), 
        inset 4px 2px 15px 1px rgba(255, 255, 0, 0.15);
}

.inner-inner-panel {
    height: 100%;
    overflow: auto;
}


@media screen and (max-width: $media_small) {
    .screen-container {
        padding: 0 1rem;
        filter: drop-shadow(-2px 1px 6px rgba(0, 0, 0, 0));
    }
    
    .inner-panel {
        padding: 0;
        border-radius: 0;
        box-shadow: none;
    }

}