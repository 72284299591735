@import '../../assets/variables.scss';

.panel {
    border-radius: .5rem;
    background-color: white;
    padding: 1.25rem;
    //border: solid 4px #ccc;
    box-shadow: inset 0 0 25px 1px #e0e0e0;
}

@media screen and (max-width: $media_small) {
    .panel {
        border-radius: 7px;
    }
}