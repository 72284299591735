.login-screen {
    display: flex;
    min-height: 100%;
    justify-content: center;
    align-items: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.error-msg-p {
    text-align: center;
    color: red
}