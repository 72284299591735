@import '../../assets/variables.scss';

$highlightColor: rgb(38, 190, 0);

.nav-bar {
    background-color: blue;
    padding: 1rem $body_padding;
    box-shadow: 0 0 10px 1px rgba(0, 0, 120, .6), inset 0 0 10px 0 rgba(0, 0, 0, .6);
    color: $nav_font_color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
        color: inherit;
        text-decoration: none;
    }

    .logo-content {
        display: flex;
        gap: 1rem;
        transition: all .3s;
        &:hover {
            color: $highlightColor;
            filter: drop-shadow(-3px 3px 3px rgb(27, 27, 34));
        }
    }
    .link-content {

        background-color: #bbb;
        color: $nav_background_color;
        border-radius: .35rem;
        overflow: hidden;
        padding: .4rem;
        font-weight: 600;
        border: inset #e0e0e0 4px;
        
        .nav-link-group {
            display: flex;
            gap: 1rem;
            font-size: $link_font_size;
        }
        .screen-active-link {
            transition: background-color .5s ease-in;
            background-color: $nav_background_color;
            color: $nav_font_color;
            cursor: default;
            border: white solid 2px;
        }
        .avatar {
            height: 2rem;
        }

        .nav-link {
            padding: 1rem;
            border-radius: .35rem;
            &:not(.screen-active-link) .link-effects {
                //transition: transform .3s ease-in-out, color .05s ease-in-out, font-weight .3s ease-in-out;
                    text-shadow: 0 2px 2px rgba(82, 48, 185, 0.7);
                &:hover  {
                    transition: all .1s ease-in-out;
                    //transform: scale(1.12);  
                    //font-weight: 900;
                    color: $highlightColor;
                    text-shadow: 0 2px 4px rgba(12, 0, 83, 0.7);
                }
                &:active {
                    color: rgb(0, 255, 255);
                    //transform: scale(1.07);
                    
                }
            }
        }
    }
}

@media screen and (max-width: $media_small) {
    .nav-bar {
        background-color: transparent;
        padding: 0 1rem;
        box-shadow: none;
        color: $nav_font_color;
        height: 12vh;
        //display:flex;
        //justify-content: stretch;
        //align-items: center;
        a {
            color: inherit;
            text-decoration: none;
        }
    
        .logo-content {
            display: none;
        }
        .link-content {
    
            //border-radius: 0px;
            overflow:auto;
            padding: 0;
            font-weight: 600;
            border: none;
            flex: 1;
            height: 12vh;
            background-color: aliceblue;
            box-shadow:  inset 0 0 10px 0px rgba(0, 116, 249, 0.46);
            
            .nav-link-group {
                justify-content: center;
                font-size: 1rem;
                height: 100%;
                //border: solid 2px black;
                align-items: center;
            }
            .screen-active-link.nav-link {
                transition: background-color .5s ease-in;
                background-color: rgba(0, 0, 83, 0.738);
                color: $nav_font_color;
                cursor: default;
                // border-top-left-radius: .35rem;
                // border-top-right-radius: .35rem;
                border-radius: .35rem;
                border: none;
            }
            .avatar {
                height: 1rem;
            }
    
            .nav-link {
                padding: .75rem;
                border-radius: 0;
            }
        }
    }
}