@import '../../assets/variables.scss';

.chat-screen {
    color: #777;
    padding: 1rem 10vw;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

}

.chat-panel {
    height: 70vh;
    display: flex;
    flex-direction: column;
}

.chat-title {
    text-align: center;
    margin-bottom: .5rem;
}

.chat-messages {
    overflow: auto;
    flex: 1;
    padding: .5rem .5rem .5rem 2.25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.send-msg-container {
    display: flex;
    align-items: flex-end;
    gap: 5px;
}

.msg-input {
    flex: 4;
    input {
        padding:.6rem;
        font-size: 1.25rem;
    }
    
}


@media screen and (max-width: $media_small) {
    .chat-screen {
        padding: 0;
        gap: 1rem;
    
    }
    
    .chat-panel {
        height: 88vh;
        display: flex;
        flex-direction: column;
    }
    
    .chat-title {
        font-size: .7rem;
        padding: .2rem;
    }
    
    .chat-messages {
        overflow:auto;
        padding: .5rem .5rem .5rem 2.25rem;
        gap: .75rem;
    }
    
    .send-msg-container {
        display: flex;
        align-items: flex-end;
        gap: 5px;
    }
    
    .msg-input {
        flex: 4;
        input {
            padding:.6rem;
            font-size: 1.25rem;
        }
        
    }
}