@import '../../assets/variables.scss';

.lang-selector {
    background-color: aliceblue;
    padding: .25rem;
    border-radius: .25rem;
    border: blue solid 1px;
}

@media screen and (max-width: $media_small) {
    .lang-selector {
        background-color:transparent;
        padding: 0rem;
        border-radius: 5rem;
        border: solid 3px rgba(0, 0, 0, .05);
        margin-bottom: .2rem;
        width: 6.5rem;
        font-size: .75rem;
        height: 50%;
        align-self: center;
    }
}