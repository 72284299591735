.select {
    display: flex;
    flex-direction: column;
    gap: .25em;

    label {
        font-size: 1.25em;
    }

    select {
        padding: .45em .6em;
        border-radius: .5em;
        font-size: 1em;
        border: 3px solid silver;
        outline: none;
        min-width: 25vw;
        
        &:focus {
            background-color: aliceblue;
            border: 3px solid rgb(110, 110, 110);
        }
    }
}