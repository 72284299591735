.account-screen {
    display: flex;
    min-height: 100%;
    justify-content: center;
    align-items: center;
}

.account-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

p.error {
    color: red;
    text-align: center;
    font-weight: 600;
    font-size: 1.25em;
}